import type { BranchProps } from "@uxu/design-system/components/molecules/tree";

type HeaderMenuConfigTypes = Array<{ href: string, title: string }>

export const headerMenuConfig: HeaderMenuConfigTypes = [
  {href: "/", title: "Blog"},
  {href: "/contact", title: "Kontakt"}
]

export const siteBarMenuConfig: Array<BranchProps> = [
  {
    title: "Wzorce projektowe", href: "/t/wzorce-projektowe",
  },
  {
    title: "Zagadki", href: "/t/zagadki",
  },
  {
    title: "tutoriale", href: "/t/tutoriale",
  },
]
