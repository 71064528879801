import type {SiteConfig, AdSlotsMap} from "@uxu/utils/common/types";
import {SiteIdEnums} from "@uxu/utils/common/enums";

const adSlotsMap: AdSlotsMap = new Map([])

export const locale = 'pl';

export const siteConfig: SiteConfig = {
    marketingToolsConfig: {
        googleTagManagerId: 'GTM-N2Z7W233',
        googleAdManagerId: '23023978625',
        adSlotsMap,
    },
    port: 3000,
    projectName: 'polskidev',
    site: {
        id: SiteIdEnums.POLSKIDEV,
        locale,
        domain: 'https://polski.dev',
        slug: '/',
        brand: "polskidev",
        shortBrand: "polskidevshort",
        defaultCover: 'https://polski.dev/defaultCover.png',
        canonicalUrl: `https://polski.dev`,
        images: [{url: 'https://polski.dev/og.png'}],
        title: 'Polski DEV - blog dla każdego programisty',
        shortname: 'pldev',
        description: 'Polski.dev - inspiracje, porady i trendy w IT dla programistów',
        authEnabled: false,
        authMatcher: [],
        switchTheme: true,
        assets: {
            cdn: []
        }
    },
    social: {
        facebook: {
            pageId: '61556322995242'
        }
    },
    client: {
        locale,
        platform: {
            isDesktop: false,
            isMobile: false,
        },
        osInfo: {
            isWindows: false,
            isLinux: false,
            isMacOS: false,
        },
        cookieConsentSettings: {
            ads: false,
            analytics: false
        },
        auth: {
            isAuth: false,
        }
    },
    admin: {
        company: {
            name: "UXU Group Sp. z o.o.",
            street: "Święty Marcin 29 / 8",
            postCode: "61-806",
            city: "Poznań",
            tax: {
                pl: {
                    nip: '7831894877',
                    regon: '527391780',
                    krs: '0001079836',
                    accountsBank: [{bank: 'Nest Bank', iban: 'PL69 2530 0008 2022 1080 2055 0001'}]
                }
            },
            contact: {
                pl: {
                    email: [{type: "main", email: "hello@uxu.pl"}],
                    phone: [{type: "mobile", number: "888 333 885"}]
                }
            }
        }
    }
};
